import { Button, Card, message, Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import { VendorForm } from 'components/molecules/VendorForm';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { EditLegalEntity, FreightVendors, freightVendorsSchema } from 'models/LegalEntities';
import { useState } from 'react';

export const AddVendorButton = () => {
  const [open, setOpen] = useState(false);
  const { setFieldValue, values: headerVals } = useFormikContext<EditLegalEntity>();
  const formik = useFormik<FreightVendors>({
    enableReinitialize: true,
    validationSchema: freightVendorsSchema,
    initialValues: {
      carrierId: '',
      containerType: '',
      vendorErpId: '',
      description: ''
    },
    onSubmit: (values) => {
      console.log(values);
      if (headerVals.freightVendors.some((vendor) => vendor.vendorErpId === values.vendorErpId || vendor.carrierId === values.carrierId)) {
        message.error('Vendor already exists');
        return;
      }
      setFieldValue('freightVendors', [...headerVals.freightVendors, values]);
      formik.resetForm();
      setOpen(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Button onClick={() => setOpen(true)}>Add Vendor</Button>
      <Modal
        closable={false}
        open={open}
        okText="Submit"
        onCancel={() => {
          setOpen(false);
          formik.resetForm();
        }}
        onOk={() => {
          formik.handleSubmit();
        }}>
        <Card headStyle={{ background: 'rgba(15, 77, 146, 0.3)' }} title={<Title level={4}>Add Vendor</Title>} style={{ marginBottom: 35 }}>
          <VendorForm />
        </Card>
      </Modal>
    </FormikProvider>
  );
};
