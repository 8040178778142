import { Space, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DeletePrinterButton } from 'components/atoms/DeletePrinterButton';
import { EditPrinterButton } from 'components/atoms/EditPrinterButton';
import { useFormikContext } from 'formik';
import { AuthorizedPrinters, EditLegalEntity } from 'models/LegalEntities';
import { FC } from 'react';

export const PrinterTable: FC = () => {
  const { values } = useFormikContext<EditLegalEntity>();

  const cols: ColumnType<AuthorizedPrinters>[] = [
    {
      title: 'Printer Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record, idx) => (
        <Space>
          <EditPrinterButton index={idx} />
          <DeletePrinterButton record={record} />
        </Space>
      )
    }
  ];
  return <Table dataSource={values.printers} columns={cols} size="small" pagination={false} />;
};
