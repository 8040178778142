import { Button, Card, message, Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { AuthorizedPrinters, EditLegalEntity } from 'models/LegalEntities';
import { useState } from 'react';
import { PrinterForm } from './molecules/PrinterForm';

export const AddPrinterButton = () => {
  const [open, setOpen] = useState(false);
  const { setFieldValue, values: headerVals } = useFormikContext<EditLegalEntity>();
  const formik = useFormik<AuthorizedPrinters>({
    enableReinitialize: true,
    initialValues: {
      dataAreaId: headerVals.dataAreaId,
      name: '',
      description: ''
    },
    onSubmit: (values) => {
      console.log(values);
      if (headerVals.printers.some((vendor) => vendor.name === values.name)) {
        message.error('Printer already exists');
        return;
      }
      setFieldValue('printers', [...headerVals.printers, values]);
      formik.resetForm();
      setOpen(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Button onClick={() => setOpen(true)}>Add Printer</Button>
      <Modal
        closable={false}
        open={open}
        okText="Submit"
        onCancel={() => {
          setOpen(false);
          formik.resetForm();
        }}
        onOk={() => {
          formik.handleSubmit();
        }}>
        <Card headStyle={{ background: 'rgba(15, 77, 146, 0.3)' }} title={<Title level={4}>Add Printer</Title>} style={{ marginBottom: 35 }}>
          <PrinterForm />
        </Card>
      </Modal>
    </FormikProvider>
  );
};
