import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';
import { SelectInput } from 'components/UI/FormItems/SelectInput';

export const VendorForm = () => {
  return (
    <Form layout="vertical">
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <TextInput fieldName="carrierId" label="Carrier Id" />
        </Col>
        <Col span={24}>
          <SelectInput options={['Package', 'Pallet'].map((opt) => ({ label: opt, value: opt }))} fieldName="containerType" label="Container Type" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="vendorErpId" label="Vendor Erp Id" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="description" label="Description" />
        </Col>
      </Row>
    </Form>
  );
};
