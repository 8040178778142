import { Space, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DeleteVendorButton } from 'components/atoms/DeleteVendorButton';
import { EditVendorButton } from 'components/atoms/EditVendorButton';
import { useFormikContext } from 'formik';
import { EditLegalEntity, FreightVendors } from 'models/LegalEntities';
import { FC } from 'react';

export const VendorTable: FC = () => {
  const { values } = useFormikContext<EditLegalEntity>();

  const cols: ColumnType<FreightVendors>[] = [
    {
      title: 'Carrier Id',
      dataIndex: 'carrierId',
      key: 'carrierId'
    },
    {
      title: 'Container Type',
      dataIndex: 'containerType',
      key: 'containerType'
    },
    {
      title: 'Erp Id',
      dataIndex: 'vendorErpId',
      key: 'vendorErpId'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record, idx) => (
        <Space>
          <EditVendorButton index={idx} />
          <DeleteVendorButton record={record} />
        </Space>
      )
    }
  ];
  return <Table dataSource={values.freightVendors} columns={cols} size="small" pagination={false} />;
};
