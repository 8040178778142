import { EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Modal, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { VendorForm } from 'components/molecules/VendorForm';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { EditLegalEntity, FreightVendors, freightVendorsSchema } from 'models/LegalEntities';
import { FC, useState } from 'react';

type Props = {
  index: number;
};

export const EditVendorButton: FC<Props> = ({ index }) => {
  const [open, setOpen] = useState(false);
  const { setFieldValue, values: headerVals } = useFormikContext<EditLegalEntity>();
  const [{ value }, , { setValue }] = useField<FreightVendors>(`freightVendors[${index}]`);
  const formik = useFormik<FreightVendors>({
    enableReinitialize: true,
    validationSchema: freightVendorsSchema,
    initialValues: {
      carrierId: value.carrierId ?? '',
      containerType: value.containerType ?? '',
      vendorErpId: value.vendorErpId ?? '',
      description: value.description ?? ''
    },
    onSubmit: (values) => {
      console.log(values);
      const filteredVendors = headerVals.freightVendors.filter((vendor) => vendor.vendorErpId !== value.vendorErpId);
      if (filteredVendors.some((vendor) => vendor.vendorErpId === values.vendorErpId || vendor.carrierId === values.carrierId)) {
        message.error('Vendor already exists');
        return;
      }
      setValue(values);
      formik.resetForm();
      setOpen(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Tooltip title="Edit">
        <Button style={{ border: '1px solid orange' }} size="small" onClick={() => setOpen(true)} icon={<EditOutlined style={{ color: 'orange' }} />} />
      </Tooltip>
      <Modal
        closable={false}
        open={open}
        okText="Submit"
        onCancel={() => {
          setOpen(false);
          formik.resetForm();
        }}
        onOk={() => {
          formik.handleSubmit();
        }}>
        <Card headStyle={{ background: 'rgba(15, 77, 146, 0.3)' }} title={<Title level={4}>Edit Vendor</Title>} style={{ marginBottom: 35 }}>
          <VendorForm />
        </Card>
      </Modal>
    </FormikProvider>
  );
};
