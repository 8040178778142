import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { EditLegalEntity, FreightVendors } from 'models/LegalEntities';
import { FC } from 'react';

type Props = {
  record: FreightVendors;
};

export const DeleteVendorButton: FC<Props> = ({ record }) => {
  const { setFieldValue, values } = useFormikContext<EditLegalEntity>();

  const handleDelete = () => {
    setFieldValue(
      'freightVendors',
      values.freightVendors.filter((vendor) => vendor.vendorErpId !== record.vendorErpId)
    );
  };
  return (
    <Tooltip title="Delete">
      <Button style={{ border: '1px solid red' }} size="small" onClick={handleDelete} icon={<DeleteOutlined style={{ color: 'red' }} />} />
    </Tooltip>
  );
};
